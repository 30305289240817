import React from 'react';
import { Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import LanguageIcon from '@mui/icons-material/Language';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';

interface Service {
  title: string;
  description: string;
	icon: any;
}

const ServicesSectionContainer = styled(Grid)(() => ({
  maxWidth: '1280px',
  margin: 'auto'
}));

const services: Service[] = [
  {
    title: 'Website Development',
    description: 'Crafting responsive and user-friendly web applications tailored to your needs.',
		icon: (<LanguageIcon sx={{fontSize: "8rem"}} />)
  },
  {
    title: 'Mobile Development',
    description: 'Building native or cross-platform mobile apps that engage users on the go.',
		icon: (<PhoneIphoneIcon sx={{fontSize: "8rem"}} />)
  },
  {
    title: 'Software Development',
    description: 'Creating robust and scalable software solutions for your business processes.',
		icon: (<WysiwygIcon sx={{fontSize: "8rem"}} />)
  },
];

const ServicesSection: React.FC = () => {
  return (
    <ServicesSectionContainer>
			<div style={{textAlign: 'center', paddingBottom: '50px'}}>
				<div style={{fontSize: '2.6rem', fontWeight: '900'}}>Our Services</div>
			</div>
      <Grid container>
        {services.map((service, index) => (
          <Grid item sm={12} md={4} key={index} style={{textAlign: 'center', padding: '25px'}}>
						{service.icon} 
            <div style={{fontSize: '2rem', fontWeight: '600', padding: '35px 0'}}>
              {service.title}
            </div>
            <Typography style={{fontSize: '1.6rem', lineHeight: '3rem'}}>{service.description}</Typography>
          </Grid>
        ))}
      </Grid>
    </ServicesSectionContainer>
  );
};

export default ServicesSection;
