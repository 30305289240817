import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MarketComponent from './MarketComponent';
import { config } from '../config';
import { Stock } from '../types';

const DailyStockPicks: React.FC = () => {

  //const [activeTab, setActiveTab] = useState(0);
  const [stockdata, setStockData] = useState<Stock[]>([]);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());
  const [openDatePicker, setOpenDatePicker] = useState(false);

  // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setActiveTab(newValue);
  // };

  useEffect(() => {
    const getData = async () => {
      try {
        const url = `${config.url.API_URL}stock`;
        const response = await fetch(url);
        const data: Stock[] = await response.json();
        if(data.length>0){
          const date = data[0].date;
          const year = date.slice(0, 4);
          const month = date.slice(4, 6);
          const day = date.slice(6, 8);
          const formattedDate = `${year}/${month}/${day}`;
          setSelectedDate(dayjs(formattedDate));
        }
        setStockData(data);
      } catch(e: Response|any) {
      }
    }
    getData();
  },[]);

  const changeDate = async (value: any) => {
    let date = value.format('YYYYMMDD');
    const url = `${config.url.API_URL}stock/`+date;
    const response = await fetch(url);
    const data: Stock[] = await response.json();
    if(data.length>0){
      const date = data[0].date;
      const year = date.slice(0, 4);
      const month = date.slice(4, 6);
      const day = date.slice(6, 8);
      const formattedDate = `${year}/${month}/${day}`;
      setSelectedDate(dayjs(formattedDate));
    }
    setStockData(data);
    setSelectedDate(value);
  }
  useEffect(() => {
    
  },[selectedDate]);
  return (
    <>
      <div className="dailystockpicksheader">Daily Stock Picks
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            open={openDatePicker}
            onOpen={() => setOpenDatePicker(true)}
            onClose={() => setOpenDatePicker(false)}
            disableFuture={true}
            slotProps={{ textField: { size: 'small', className:'datepicker', InputProps: { readOnly: true }, onClick: ()=>setOpenDatePicker(!openDatePicker) } }}
            value={selectedDate}
            onChange={(value: any)=>changeDate(value)}
            sx={{ cursor: 'pointer', color: '#ffffff' }}
          />
        </LocalizationProvider>
      </div>
      <div className="content">
        <Box >
          {/* <Tabs className="tabs" value={activeTab} onChange={handleChange} aria-label="simple tabs example">
            <Tab label="US" />
            <Tab label="Mainland China" />
            <Tab label="HK" />
          </Tabs> */}
          <div className="panel">
            <MarketComponent data={stockdata}/>
          </div>
          {stockdata.length===0&&<div className="text-center margin-top-20">No Stock Picks on selected date</div>}
        </Box>
      
      </div>
    </>
  );
};

export default DailyStockPicks;
