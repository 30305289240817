import React from 'react';
import { Grid } from '@mui/material';
import IBKRlogo from '../assets/images/IBKR logo.png';
import IGlogo from '../assets/images/ig logo.jpg';
import platformlogo from '../assets/images/trading platform.png';

const QuantDescription: React.FC = () => {
	return (
		<>
			{/* <img src={IBKRlogo} alt="IBKR" />
			<img src={IGlogo} alt="IG" />
			<img src={platformlogo} alt="trading platform" /> */}
			<div className="trading-platform-intro">
				<h4>Discover the Future of Trading</h4>
				<p>
				Step into the world of elite trading with our state-of-the-art <strong>Quant Trading Platform</strong>. Crafted for the discerning investor, our platform offers an unparalleled integration with industry giants <a href="https://www.ibkr.com" target="_blank" rel="noreferrer"><strong>IBKR</strong></a> and <a href="https://www.ig.com"  target="_blank" rel="noreferrer"><strong>IG</strong></a>, providing a gateway to the pinnacle of automated trading technology.
				</p>
				<Grid container>
					<Grid item xs={12} md={3}></Grid>
					<Grid item xs={12} md={3}><a href="https://www.ibkr.com" target="_blank" rel="noreferrer"><img className="logo" src={IBKRlogo} alt="IBKR" /></a></Grid>
					<Grid item xs={12} md={3}><a href="https://www.ig.com"  target="_blank" rel="noreferrer"><img className="logo" src={IGlogo} alt="IG" /></a></Grid>
					<Grid item xs={12} md={3}></Grid>
				</Grid>
				<p>
          Experience the power of perpetual market presence as our sophisticated algorithms deploy diverse trading strategies, tirelessly working <strong>24 hours</strong>. Imagine the potential of having a digital trading assistant that doesn't sleep, meticulously executing up to <strong>119 hours a week</strong> of trading on your behalf, each week.
        </p>
				<img className="platformlogo" src={platformlogo} alt="trading platform" />
        <p>
          This is not just a platform, it's a revolution in your financial growth strategy, currently in the final stages of rigorous internal testing. It's an exclusive opportunity to be at the forefront of trading innovation—where technology meets ambition.
        </p>
				<p className="note">
					<em>Note: This feature is currently undergoing internal testing and is not available to the public yet.</em>
				</p>
				<p className="risk-disclaimer">
          <strong>Risk Disclaimer:</strong> Please be aware that all trading activities involve risk. While our platform strives to provide powerful trading tools, we do not assume responsibility for any losses incurred. It is important for users to understand the risks associated with trading and to trade responsibly.
        </p>
			</div>
		</>
	);
}

export default QuantDescription;