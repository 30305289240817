import React from 'react';
import { Grid, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import icon from '../assets/images/quanttrader.png';

const QuantTraderSectionContainer = styled(Grid)(() => ({
  maxWidth: '1280px',
  margin: 'auto'
}));

const Icon = styled('img')({
	width: '60%',
});

const QuantTraderSection: React.FC = () => {

  return (
		<QuantTraderSectionContainer container>
			<Grid item xs={12} md={6}>
				<Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="calc(100% - 60px)"
					style={{padding: '30px 25px'}}
        	>
					<div style={{textAlign: 'center'}}>
						<div style={{fontSize: '2.6rem', fontWeight: '900'}}>Quant Trading</div>
						<div style={{fontSize: '1.6rem', lineHeight: '3rem', fontWeight: '300', paddingTop: '25px'}}>
						Whether you’re a seasoned quant or a curious explorer, quant trading offers endless possibilities. So, sharpen your algorithms, embrace the data, and let the numbers lead you to profit.
						</div>
					</div>
				</Box>
      </Grid>
			<Grid item xs={12} md={6}>
				<Box
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
					style={{overflow: 'hidden'}}
        	>
          <Icon
              src={icon} 
							alt="Quant Trading"
            />
        </Box>
      </Grid>
    </QuantTraderSectionContainer>
  );
};

export default QuantTraderSection;
