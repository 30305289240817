import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, Alert, Paper, AlertColor, AlertPropsColorOverrides } from '@mui/material';
import { styled } from '@mui/material/styles';
import Footer from '../components/Footer';
import { delay } from '../services';
import './styles/contact.scss';
import { postContact } from '../api'; 
import { OverridableStringUnion } from '@mui/types';

const ContactContainer = styled(Grid)(() => ({
  maxWidth: '1280px',
  margin: '0 auto',
  paddingTop: '240px',
  paddingBottom: '140px'
}));

const Contact: React.FC = () => {
	const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [msg, setMsg] = useState('');
  const [severity, setSeverity] = useState<OverridableStringUnion<AlertColor, AlertPropsColorOverrides>>('success');
  const [showMessage, setShowMessage] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);

  useEffect(() => {
    document.title = 'Contact - Iceloof';
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // Validate form data (e.g., check email format)
    if (formData.name.length === 0) {
      // Show validation error
      setMsg('Please input your name');
      setShowMessage(true);
      await delay(5000);
      setShowMessage(false);
      return;
    }
    if (!formData.email.match(/^\S+@\S+\.\S+$/)) {
      // Show validation error
      setMsg('Please input valid email');
      setShowMessage(true);
      await delay(5000);
      setShowMessage(false);
      return;
    }
    if (formData.message.length === 0) {
      // Show validation error
      setMsg('Please input your message');
      setShowMessage(true);
      await delay(5000);
      setShowMessage(false);
      return;
    }
    // Handle form submission (e.g., send data to server)
    // Replace this with your actual submission logic
    try{
      setDisabledBtn(true);
      await postContact(formData);
      setDisabledBtn(false);
      setMsg('Your message is received, we will contact you soon');
      setSeverity('success');
      setShowMessage(true);
      // Hide the message after 5 seconds
      await delay(5000);
      setShowMessage(false);
    } catch(e: Response|any) {
      setDisabledBtn(false);
      setMsg(e.data.msg);
      setSeverity('warning');
      setShowMessage(true);
      // Hide the message after 5 seconds
      await delay(5000);
      setShowMessage(false);
    }
  };
  return (
    <>
      <div className="Contact">
        <div className="bg">
          <div className="filteredbg">
            <ContactContainer>
              <Paper sx={{margin: 'auto', padding: '25px', maxWidth: '480px', textAlign: 'center'}}>
                <form onSubmit={handleSubmit}>
                  <div className="title">Contact Us</div>
                  {showMessage && 
                  <>
                    <div style={{margin: '0 auto', paddingBottom: '15px', width: '300px'}}>
                      <Alert severity={severity}>
                        {msg}
                      </Alert>
                    </div>
                  </>}
                  <TextField
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    size="small"
                    required
                    fullWidth
                    sx={{ marginTop: '15px' }}
                  />
                  <TextField
                    label="Email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    size="small"
                    required
                    fullWidth
                    sx={{ marginTop: '15px' }}
                  />
                  <TextField
                    label="Message"
                    name="message"
                    multiline
                    rows={4}
                    value={formData.message}
                    onChange={handleInputChange}
                    size="small"
                    required
                    fullWidth
                    sx={{ marginTop: '15px' }}
                  />
                  <Button sx={{ marginTop: '25px' }} disabled={disabledBtn} size="small" type="submit" variant="contained" color="primary">
                    Submit
                  </Button>
                  
                </form>
              </Paper>
            </ContactContainer>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;