import React, { useState } from 'react';
import { Modal, Box } from '@mui/material';
import { config } from '../config';

const Footer: React.FC = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: 'auto',
    maxHeight: '100%',
    overflowY: 'auto',
    bgcolor: '#f1f1f1',
    color: '#1d1d1d',
    border: '2px solid #1d1d1d',
    borderRadius: '15px',
    boxShadow: 24,
    p: 4,
  };

  const currentYear = new Date().getFullYear();

  const openModal = () => {
    handleOpen();
  }

  return (
    <>
      <footer style={{ textAlign: 'center', padding: config.subdomain === 'marketwatcher'?'12px 0':'25px 0', height: '22px'}}>
        &copy; {currentYear} Iceloof. All rights reserved. | <div className="pointer inline" onClick={openModal}>Terms and Conditions</div>
      </footer>
      <Modal
        open={open}
        onClose={handleClose}
        className="news-modal"
        aria-labelledby="news-modal-title"
        aria-describedby="news-modal-description"
      >
        <Box sx={style}>
        <div style={{textAlign: 'center', fontSize: '2.2rem', fontWeight: '900'}}>Terms and Conditions</div>
        <div style={{fontSize: '1rem'}}>Last updated: April, 2024</div>
        <div style={{fontSize: '1rem'}}>Please read these terms and conditions carefully before using Our Service.</div>
        <div style={{fontSize: '1rem', fontWeight: '600'}}>Interpretation and Definitions</div>
        <div style={{fontSize: '1rem', fontWeight: '600'}}>Interpretation</div>
        <div style={{fontSize: '1rem'}}>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</div>
        <div style={{fontSize: '1rem', fontWeight: '600'}}>Definitions</div>
        <div style={{fontSize: '1rem'}}>For the purposes of these Terms and Conditions:
          <ul>
            <li><b>Company</b> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Iceloof.</li>
            <li><b>Service</b> refers to the Website.</li>
            <li><b>Terms and Conditions</b> (also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.</li>
            <li><b>Website</b> refers to Iceloof.com, accessible from https://www.iceloof.com</li>
          </ul>
        </div>
        <div style={{fontSize: '1rem', fontWeight: '600'}}>Acknowledgment</div>
        <div style={{fontSize: '1rem'}}>By accessing or using the Service, You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service.</div>
        <div style={{fontSize: '1rem', fontWeight: '600'}}>Intellectual Property</div>
        <div style={{fontSize: '1rem'}}>The Service and its original content, features and functionality are and will remain the exclusive property of the Company and its licensors.</div>
        <div style={{fontSize: '1rem', fontWeight: '600'}}>Your Feedback to Us</div>
        <div style={{fontSize: '1rem'}}>You assign all rights, title and interest in any Feedback You provide the Company. If for any reason such assignment is ineffective, You agree to grant the Company a non-exclusive, perpetual, irrevocable, royalty free, worldwide right and license to use, reproduce, disclose, sub-license, distribute, modify and exploit such Feedback without restriction.</div>
        <div style={{fontSize: '1rem', fontWeight: '600'}}>Links to Other Websites</div>
        <div style={{fontSize: '1rem'}}>Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.</div>
        <div style={{fontSize: '1rem'}}>The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.</div>
        <div style={{fontSize: '1rem', fontWeight: '600'}}>Termination</div>
        <div style={{fontSize: '1rem'}}>We may terminate or suspend Your access immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions.</div>
        <div style={{fontSize: '1rem', fontWeight: '600'}}>Limitation of Liability</div>
        <div style={{fontSize: '1rem'}}>Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You through the Service.</div>
        <div style={{fontSize: '1rem', fontWeight: '600'}}>"AS IS" and "AS AVAILABLE" Disclaimer</div>
        <div style={{fontSize: '1rem'}}>The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.</div>
        <div style={{fontSize: '1rem', fontWeight: '600'}}>Governing Law</div>
        <div style={{fontSize: '1rem'}}>The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service. Your use of the Application may also be subject to other local, state, national, or international laws.</div>
        <div style={{fontSize: '1rem', fontWeight: '600'}}>Contact Us</div>
        <div style={{fontSize: '1rem'}}>If you have any questions about these Terms and Conditions, You can contact us: By visiting this page on our website: <a href="https://www.iceloof.com/contact" target="_blank" rel="noreferrer">Contact</a></div>
        </Box>
      </Modal>
    </>
  );
};

export default Footer;
