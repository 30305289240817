import { userActionTypes } from '../actions/user';

const userInitialState = {
  user: null,
  token: '',
  expired: '',
  logined: false,
  rememberLogin: {remember: false, username: '', password: ''}
};

const userReducer = (state = userInitialState, action: any) => {
  switch (action.type) {
    case userActionTypes.UPDATE_USER:
      return {
        ...state,
        user: action.payload.user,
      };
    case userActionTypes.UPDATE_TOKEN:
      return {
        ...state,
        token: action.payload.token,
      };
    case userActionTypes.UPDATE_EXPIRED:
      return {
        ...state,
        expired: action.payload.expired,
      };
    case userActionTypes.LOGINED:
      return {
        ...state,
        logined: action.payload.logined,
      };
    case userActionTypes.REMEMBERLOGIN:
      return {
        ...state,
        rememberLogin: action.payload.rememberLogin,
      };
    default:
      return state;
  }
};

export default userReducer;