import { User } from '../../types';

export const userActionTypes = {
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_TOKEN: 'UPDATE_TOKEN',
  UPDATE_EXPIRED: 'UPDATE_EXPIRED',
  LOGINED: 'LOGINED',
  REMEMBERLOGIN: 'REMEMBERLOGIN',
};

export const updateUser = (user: User) => (dispatch: any) => {
  return dispatch({
    type: userActionTypes.UPDATE_USER,
    payload: { user: user },
  });
};

export const updateToken = (token: string) => (dispatch: any) => {
  return dispatch({
    type: userActionTypes.UPDATE_TOKEN,
    payload: { token: token },
  });
};

export const updateExpired = (expired: string) => (dispatch: any) => {
  return dispatch({
    type: userActionTypes.UPDATE_EXPIRED,
    payload: { expired: expired },
  });
};

export const updateLogined = (logined: boolean) => (dispatch: any) => {
  return dispatch({
    type: userActionTypes.LOGINED,
    payload: { logined: logined },
  });
};

export const updateRememberLogin = (data: {remember: boolean, username: string, password: string}) => (dispatch: any) => {
  return dispatch({
    type: userActionTypes.REMEMBERLOGIN,
    payload: { rememberLogin: {remember: data.remember, username: data.username, password: data.password} },
  });
};