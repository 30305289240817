import React from 'react';
import HTMLFlipBook from 'reactebook';
import './styles/ebook.scss';

interface EBookProps {
  elements?: any[];
	width?: number;
	height?: number;
}

const EBook: React.FC<EBookProps> = ({elements, width, height}) => {
	const pages = elements?elements.length:0;

	return (
		<>
			<HTMLFlipBook className="flip-book" width={width} height={height}>
				{elements?.map((element, index)=>(
					<div className={index===0?"page page-cover page-cover-top":index===pages-1?"page page-cover page-cover-bottom":"page"} key={index}>
						<div className="page-content">
							{element}
						</div>
						<div className="page-footer">Page {index+1}/{pages}</div>
					</div>
				))}
			</HTMLFlipBook>
		</>
	);
};

export default EBook;