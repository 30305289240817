import axios from 'axios';
import { config } from '../config';
import { TradingAccount, TradingStrategy, TradingSystem } from '../types';

export const tradingAccount = (token: string): any => {
  return new Promise((resolve, reject) => {
    axios
      .get(config.url.API_URL+'quant/tradingaccounts', {headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization':'Bearer '+token }})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response ? err.response : err);
        });
  });
}

export const tradingStatus = (token: string, account: TradingAccount): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(config.url.API_URL+'quant/tradingstatus', account, {headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization':'Bearer '+token }})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response ? err.response : err);
        });
  });
}

export const tradingSystem = (token: string, account: TradingAccount): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(config.url.API_URL+'quant/tradingsystem', account, {headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization':'Bearer '+token }})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response ? err.response : err);
        });
  });
}

export const updatetradingSystem = (token: string, system: TradingSystem): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(config.url.API_URL+'quant/tradingsystem/update', system, {headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization':'Bearer '+token }})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response ? err.response : err);
        });
  });
}

export const restartSystem = (token: string, type: string, id: number): any => {
	return new Promise((resolve, reject) => {
    axios
      .post(config.url.API_URL+'quant/restart/'+type+'/'+id, {}, {headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization':'Bearer '+token }})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response ? err.response : err);
        });
  });
}

export const stopSystem = (token: string, type: string, id: number): any => {
	return new Promise((resolve, reject) => {
    axios
      .post(config.url.API_URL+'quant/stop/'+type+'/'+id, {}, {headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization':'Bearer '+token }})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response ? err.response : err);
        });
  });
}

export const tradingHolding = (token: string, account: TradingAccount): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(config.url.API_URL+'quant/tradingholding', account, {headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization':'Bearer '+token }})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response ? err.response : err);
        });
  });
}

export const transactions = (token: string, page: number): any => {
  return new Promise((resolve, reject) => {
    axios
      .get(config.url.API_URL+'quant/tradingtransactions?page='+page, {headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization':'Bearer '+token }})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response ? err.response : err);
        });
  });
}

export const transactionsById = (token: string, id: number, page: number): any => {
  return new Promise((resolve, reject) => {
    axios
      .get(config.url.API_URL+'quant/tradingtransactions/'+id+'?page='+page, {headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization':'Bearer '+token }})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response ? err.response : err);
        });
  });
}

export const historicalData = (token: string, currency: string, period: string): any => {
	return new Promise((resolve, reject) => {
    axios
      .get(config.url.API_URL+'quant/tradingstatus?currency='+currency+'&period='+period, {headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization':'Bearer '+token }})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response ? err.response : err);
        });
  });
}

export const historicalDataByAccount = (token: string, id: number, currency: string, period: string): any => {
	return new Promise((resolve, reject) => {
    axios
      .get(config.url.API_URL+'quant/tradingstatus/'+id+'?currency='+currency+'&period='+period, {headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization':'Bearer '+token }})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response ? err.response : err);
        });
  });
}

export const getDailyPl = (token: string, date: string): any => {
	return new Promise((resolve, reject) => {
    axios
      .get(config.url.API_URL+'quant/dailypl?date='+date, {headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization':'Bearer '+token }})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response ? err.response : err);
        });
  });
}

export const getDailyPlByAccount = (token: string, id: number, date: string): any => {
	return new Promise((resolve, reject) => {
    axios
      .get(config.url.API_URL+'quant/dailypl/'+id+'?date='+date, {headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization':'Bearer '+token }})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response ? err.response : err);
        });
  });
}

export const getStrategyByAccount = (token: string, id: number): any => {
	return new Promise((resolve, reject) => {
    axios
      .get(config.url.API_URL+'quant/tradingstrategy/'+id, {headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization':'Bearer '+token }})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response ? err.response : err);
        });
  });
}

export const updateStrategy = (token: string, strategy: TradingStrategy): any => {
	return new Promise((resolve, reject) => {
    axios
      .post(config.url.API_URL+'quant/tradingstrategy/update', strategy, {headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization':'Bearer '+token }})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response ? err.response : err);
        });
  });
}

export const quantApi = {
  tradingAccount: tradingAccount,
	tradingStatus: tradingStatus,
	tradingSystem: tradingSystem,
	updatetradingSystem: updatetradingSystem,
	restartSystem: restartSystem,
	stopSystem: stopSystem,
	tradingHolding: tradingHolding,
	transactions: transactions,
	transactionsById: transactionsById,
	historicalData: historicalData,
	historicalDataByAccount: historicalDataByAccount,
  getDailyPl: getDailyPl,
  getDailyPlByAccount: getDailyPlByAccount,
  getStrategyByAccount: getStrategyByAccount,
  updateStrategy: updateStrategy
}