import axios from 'axios';
import { config } from '../config';

export const user = (token: string): any => {
  return new Promise((resolve, reject) => {
    axios
      .get(config.url.API_URL+'user', {headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization':'Bearer '+token }})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response ? err.response : err);
        });
  });
}

export const login = (username: string, password: string): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(config.url.API_URL+'user/auth', 
        { 
          username:username, 
          password:password 
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response ? err.response : err);
        });
  });
}

export const logout = (token: string): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(config.url.API_URL+'user/logout', {}, {headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization':'Bearer '+token }})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response ? err.response : err);
        });
  });
}

export const refreshToken = (token: string): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(config.url.API_URL+'user/refresh', {token: token})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response ? err.response : err);
        });
  });
}

export const userApi = {
  user: user,
  login: login,
  logout: logout,
  refreshToken: refreshToken
}