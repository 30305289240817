import { useEffect, useState } from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import asiaGeoJson from './geojson/asia.geo.json';
import africaGeoJson from './geojson/africa.geo.json';
import europeGeoJson from './geojson/europe.geo.json';
import northamericaGeoJson from './geojson/northamerica.geo.json';
import southamericaGeoJson from './geojson/southamerica.geo.json';
import oceaniaGeoJson from './geojson/oceania.geo.json';
import otherGeoJson from './geojson/other.geo.json';
import { TravelAdvisory } from '../types';
import { delay } from '../services';

interface Props {
	data: TravelAdvisory[]
}
const TravelMap: React.FC<Props> = ({ data }) => {
	const [geoDataList, setGeoDataList] = useState<any[]>([]);
    
    useEffect(() => {
        setGeoDataList([]);
        const risks = ['Other','Normal Security Precautions', 'Exercise Increased Caution','Avoid Non-Essential Travel','Do Not Travel','No Data'];
        const unmatchName = (source: string, target: string) => {
            return (target==='Palestine'&&(source.indexOf('West Bank')>-1||source.indexOf('Gaza')>-1||source.indexOf('Palestinian ')>-1))
            ||(target==='Turkey'&&source.indexOf('Türkiye')>-1)
            ||(target==='Ivory Coast'&&source.indexOf('Cote d\'Ivoire')>-1)
            ||(target==='Kyrgyzstan'&&source.indexOf('Kazakhstan')>-1)
            ||(target==='Czech Republic'&&source.indexOf('Czechia')>-1)
            ||(target==='Democratic Republic of the Congo'&&source.indexOf('Democratic Republic of Congo (Kinshasa)')>-1)
            ||(target==='Republic of the Congo'&&source.indexOf('Republic of Congo (Brazzaville)')>-1)
            ||(target==='Macau'&&source.indexOf('Macao')>-1)
            ;
        }
        // Function to update GeoJSON features with levels from data list
        const updateGeoJsonWithLevels = (geoJson: any, data: any) => {
            return {
            ...geoJson,
            features: geoJson.features.map((feature: any) => {
                const match = data.find((item: TravelAdvisory) => item.to.replace(/\s+/g, '').toLowerCase() === feature.properties.name.replace(/\s+/g, '').toLowerCase()||item.to.replace(/\s+/g, '').toLowerCase().indexOf(feature.properties.name.replace(/\s+/g, '').toLowerCase()) > -1||feature.properties.name.replace(/\s+/g, '').toLowerCase().indexOf(item.to.replace(/\s+/g, '').toLowerCase()) > -1||unmatchName(item.to,feature.properties.name));
                if (match) {
                    feature.properties.level = match.level;
                    feature.properties.risk = risks[match.level];
                    feature.properties.date = match.date;
                } else if (feature.properties.name === 'United States of America' && data.length > 0 && data[0].from === 'US') {
                    feature.properties.level = 1;
                    feature.properties.risk = risks[1]
                    feature.properties.date = '';
                } else if (feature.properties.name === 'Canada' && data.length > 0 && data[0].from === 'CA') {
                    feature.properties.level = 1;
                    feature.properties.risk = risks[1]
                    feature.properties.date = '';
                } else if (feature.properties.name === 'New Zealand' && data.length > 0 && data[0].from === 'NZ') {
                    feature.properties.level = 1;
                    feature.properties.risk = risks[1]
                    feature.properties.date = '';
                } else {
                    feature.properties.level = 5;
                    feature.properties.risk = 'No Data'
                    feature.properties.date = '';
                }
                return feature;
            })
            };
        };  
        // Set the GeoJSON data
        const asia = updateGeoJsonWithLevels(asiaGeoJson, data);
        const africa = updateGeoJsonWithLevels(africaGeoJson, data);
        const europe = updateGeoJsonWithLevels(europeGeoJson, data);
        const northamerica = updateGeoJsonWithLevels(northamericaGeoJson, data);
        const southamerica = updateGeoJsonWithLevels(southamericaGeoJson, data);
        const oceania = updateGeoJsonWithLevels(oceaniaGeoJson, data);
        const other = updateGeoJsonWithLevels(otherGeoJson, data);
        delay(500).then(()=>setGeoDataList([asia,africa,europe,northamerica,southamerica,oceania,other]));
    }, [data]);

	// Function to get color based on level
    const getColor = (level: number) => {
        switch (level) {
            case 0: return '#5ffffd';
            case 1: return '#06b628';
            case 2: return '#fffa4e';
            case 3: return '#ffb602';
            case 4: return '#ff0000';
            case 5: return '#ffffff';
            default: return '#ffffff';
        }
    };
    
    // Function to style the polygons
    const polygonStyle = (feature: any) => ({
        color: getColor(feature.properties.level),
        weight: 2,
        fillOpacity: 0.3
    });

    const handleMouseOver = (e: any) => {
        e.target.setStyle({
            weight: 3
        });
    };

    const handleMouseOut = (e: any) => {
        e.target.setStyle({
            weight: 1
        });
    };

    return (
        <MapContainer
        center={[12, 20]} // Initial center of the map
        zoom={2} // Initial zoom level
        style={{ height: '100vh', width: '100%', minHeight: '480px', maxHeight: '640px', position: 'relative' }}
        >
            {geoDataList.map((geoData, index) => (
            <GeoJSON 
                        key={index} 
                        data={geoData} 
                        style={polygonStyle} 
                        onEachFeature={(feature, layer) => {
                            layer.on({
                                mouseover: handleMouseOver,
                                mouseout: handleMouseOut
                            });
                            layer.bindTooltip(
                                `Name: <b>${feature.properties.name}</b><br>Risk Level: <b>${feature.properties.risk}</b><br>Last Updated: <b>${feature.properties.date}</b>`,
                                {
                                  permanent: false,
                                  direction: "auto",
                                  interactive: true,
                                  sticky: true,
                                  offset: [0, 0],
                                  opacity: 0.9
                                }
                            );
                        }} />
            ))}
            <TileLayer
                url="http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
            />
        </MapContainer>
    );
};

export default TravelMap;