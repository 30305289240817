import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as moment from "moment";
import * as momenttimezone from "moment-timezone";
import { Base64 } from 'js-base64';
import { formatInTimeZone } from 'date-fns-tz';

export const withRouter = (Component: any) =>  {
  function ComponentWithRouterProp(props: any) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        navigate={navigate}
        location={location}
        params={params}
        {...props}
      />
    );
  }
  return ComponentWithRouterProp;
}

export const delay = async (ms: number) => {
  return new Promise( resolve => setTimeout(resolve, ms) );
}

export const changeFormat = (num: number) => {
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + '%';
}

export const numberFormat = (num: number, precision?: number) => {
  let dec = num.toFixed(precision?precision:2).split(".")[1];
  if(precision !== 0){
    return Math.floor(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')+'.'+dec;
  }else{
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }
}

export const timestamptoString = (timestamp: number|string, format: string) => {
  return moment.utc(Number(timestamp)*1000).local().format(format);
}

export const localTime = (format: string) => {
  return moment.utc().local().format(format);
}

export const utcTime = (format: string) => {
  return moment.utc().format(format);
}

export const etTime = (format: string) => {
  return momenttimezone.tz('America/New_York').format(format);
}

export const utcConvert = (time: string, tz: string, timeformat: string) => {
  const date = new Date(time.replace(' ','T')+'Z')
  // Format the result (optional)
  return formatInTimeZone(date, tz, timeformat);
}

export const getFirstDayOfCurrentMonthInNY = (): string => {
  const lastDayOfPreviousMonth = momenttimezone.tz('America/New_York')
  .subtract(1, 'month') // Go back one month
  .endOf('month') // Go to the end of that month
  .format('YYYY-MM-DD'); // Format the date
  return lastDayOfPreviousMonth;
};

export const getLastDayOfMarchNY = (): string => {
  // Get the current date in New York timezone
  const nowNY = momenttimezone.tz('America/New_York');
  // Determine the current year or the previous year based on the current date
  const year = nowNY.isAfter(nowNY.clone().month('March').endOf('month')) ? nowNY.year() : nowNY.year() - 1;
  // Get the last day of March for the determined year
  const lastDayOfMarchNY = moment.tz(`${year}-03-31`, 'America/New_York').format('YYYY-MM-DD');
  return lastDayOfMarchNY;
}

export const deepEqual = (obj1: any, obj2: any): boolean => {
  if (obj1 === obj2) return true;

  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') return false;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

export const encrypt = (text: string): string => {
  return Base64.encode(text);
};

// Function to decrypt the encrypted string
export const decrypt = (encryptedText: string): string => {
  return Base64.decode(encryptedText);
};
