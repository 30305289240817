import React, { useEffect } from 'react';
import { withRouter } from '../services';
import { connect } from 'react-redux';
import { Box } from '@mui/material';
import './styles/quanttrader.scss';
import QuantDescription from '../components/QuantDescription';
import QuantDashboard from '../components/QuantDashboard';
import Footer from '../components/Footer';
import { User } from '../types';

type Props = {
  user: User,
	logined: boolean,
  expired: string,
}
const QuantTrader: React.FC<Props> = (props) => {
  useEffect(() => {
    document.title = 'Quant Trader - Iceloof';
  }, []);

  return (
    <>
      <Box className="quanttrader">
        {props.logined&&props.user.roles.find(role=>role.name==='quant')?<QuantDashboard />:<QuantDescription />}
      </Box>
      <Footer />
    </>
  );
};

const mapStateToProps = (state: any) => ({
	user: state.user.user,
	logined: state.user.logined,
  expired: state.user.expired,
});

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuantTrader));