import React, { useEffect } from 'react';
import { Grid, Box, Paper } from '@mui/material';
import News from '../components/News';
import DailyStockPicks from '../components/DailyStockPicks';
import Footer from '../components/Footer';
import './styles/marketwatcher.scss';

const MarketWatcher: React.FC = () => {

  useEffect(() => {
    document.title = 'Market Watcher - Iceloof';
  }, []);

  return (
    <>
      <Box className="marketwatcher">
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Paper className="news">
              <News />
            </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper className="dailystockpicks">
              <DailyStockPicks />
            </Paper>
          </Grid>
        </Grid>
        <Footer />
      </Box>
    </>
  );
};

export default MarketWatcher;