import { useEffect, useState } from 'react';
import L, { LatLngExpression } from 'leaflet';
import moment from 'moment-timezone';
import { MapContainer, TileLayer, useMap, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const Maps = () => {
	
	// Custom hook to handle the map movement
  const MoveMap = () => {
		const map = useMap();
    useEffect(() => {
      // Disable map interactions
      map.dragging.disable();
      map.touchZoom.disable();
      map.doubleClickZoom.disable();
      map.scrollWheelZoom.disable();
      map.boxZoom.disable();
      map.keyboard.disable();
      if (map.tap) map.tap.disable();

      // Remove zoom control
      map.removeControl(map.zoomControl);

      // Function to pan the map from left to right
      const panMap = () => {
        let currentCenter = map.getCenter();
        let newLongitude = currentCenter.lng + 2; // Adjust the value to control the speed
        if (newLongitude > 180) {
          newLongitude -= 360;
        }
        map.flyTo(new L.LatLng(currentCenter.lat, newLongitude), map.getZoom(), {
          animate: true,
          duration: 0.5
        });
      };

      // Start panning the map every 500 milliseconds
      const intervalId = setInterval(panMap, 100);

      // Clear the interval when the component is unmounted
      return () => clearInterval(intervalId);
    }, [map]);
	
    return null;
  };

	const SetZoom = () => {
		const map = useMap();
    useEffect(() => {
      // Function to set zoom based on screen height
      const setZoomBasedOnHeight = () => {
        const screenHeight = window.innerHeight;
        const zoomLevel = screenHeight < 420 ? 1 : (screenHeight < 720 ? 2 : 3);
        map.setZoom(zoomLevel);
      };

      // Set zoom on initial load
      setZoomBasedOnHeight();

      // Add event listener for window resize
      window.addEventListener('resize', setZoomBasedOnHeight);

      // Clean up event listener
      return () => window.removeEventListener('resize', setZoomBasedOnHeight);
    }, [map]);

    return null;
  };
	
	const cities = [
		{ name: 'Sydney', coords: [-33.8688, 151.2093], timezone: 'Australia/Sydney' },
		{ name: 'Hong Kong', coords: [22.3193, 114.1694], timezone: 'Asia/Hong_Kong' },
		{ name: 'London', coords: [51.5074, -0.1278], timezone: 'Europe/London' },
    { name: 'London', coords: [51.5074, 359.8722], timezone: 'Europe/London' },
		{ name: 'New York', coords: [40.7128, -74.0060], timezone: 'America/New_York' },
    { name: 'New York', coords: [40.7128, 285.9940], timezone: 'America/New_York' },
		{ name: 'Tokyo', coords: [35.6895, 139.6917], timezone: 'Asia/Tokyo' },
  	{ name: 'Singapore', coords: [1.3521, 103.8198], timezone: 'Asia/Singapore' }
	];

	type City = {name: string, coords: LatLngExpression, timezone: string};

	const CityMarker = (props: {city: City}) => {
		const [time, setTime] = useState('');
		const map = useMap();

		useEffect(() => {
			const interval = setInterval(() => {
				setTime(moment().tz(props.city.timezone).format('HH:mm:ss'));
			}, 1000);
	
			// Open the popup manually
			const popup = L.popup()
				.setLatLng(props.city.coords)
				.setContent(`${props.city.name}: ${time}`)
				.addTo(map);

			// Open the popup
			popup.openOn(map);

			return () => {
				clearInterval(interval);
				map.removeLayer(popup);
			};
		}, [props.city, map, time]);

		// Create an invisible marker
		const invisibleIcon = L.divIcon({ className: 'invisible-marker' });

		return <Marker position={props.city.coords} icon={invisibleIcon} />;
	};

  return (
    <MapContainer
      center={[12, 80]} // Initial center of the map
      zoom={3} // Initial zoom level
      style={{ height: '100vh', width: '100%', minHeight: '480px', maxHeight: '720px', position: 'relative' }}
    >
      <TileLayer
        url="http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
      />
      <MoveMap />
      <SetZoom />
      {cities.map((city: any, index: number) => (
        <CityMarker key={index} city={city} />
      ))}
    </MapContainer>
  );
};

export default Maps;