import React from 'react';
import { Grid } from '@mui/material';
import { changeFormat, numberFormat } from '../services';
import { Stock } from '../types';

interface Props {
	data: Stock[]
}
const MarketCompoent: React.FC<Props> = ({ data }) => {
 
	const Item = (item: Stock, index: number) => (
		<Grid container spacing={2} key={index}>
      <Grid item xs={12} lg={6}>
				<table>
					<tbody>
						<tr>
							<td>Name: </td>
							<td>{item.name}</td>
						</tr>
						<tr>
							<td>Ticker: </td>
							<td>{item.ticker}</td>
						</tr>
						<tr>
							<td>Exchange: </td>
							<td>{item.exchange}</td>
						</tr>
						<tr>
							<td>Price: </td>
							<td>${numberFormat(item.price)} (<span className={`bold ${item.change>=0?'fg-positive':'fg-negative'}`}>{changeFormat(item.change)}</span>)</td>
						</tr>
						<tr>
							<td>Volume: </td>
							<td>{numberFormat(item.volume,0)}</td>
						</tr>
						{/* <tr>
							<td>Industry: </td>
							<td>{item.industry}</td>
						</tr> */}
						<tr>
							<td>Market Cap.: </td>
							<td>${numberFormat(item.marketcap,0)}</td>
						</tr>
						<tr>
							<td>News: </td>
							<td><div className={`sentiment ${item.sentiment===1?'bg-positive':item.sentiment===-1?'bg-negative':'bg-neutral'}`}>{item.sentiment===1?'Positive':item.sentiment===-1?'Negative':'Neutral'}</div></td>
						</tr>
						<tr>
							<td>60-Day Change: </td>
							<td>{changeFormat(item.chg60)}</td>
						</tr>
						<tr>
							<td>Change Range: </td>
							<td><span className={`bold ${item.chgL>=0?'fg-positive':'fg-negative'}`}>{changeFormat(item.chgL)}</span> - <span className={`bold ${item.chgH>=0?'fg-positive':'fg-negative'}`}>{changeFormat(item.chgH)}</span></td>
						</tr>
						<tr>
							<td>Change Std: </td>
							<td>{numberFormat(item.chgStd)}</td>
						</tr>
						<tr>
							<td>Value at Risk(95%): </td>
							<td>{numberFormat(item.vaR)}%</td>
						</tr>
						<tr>
							<td>Keywords: </td>
							<td>{item.keywords}</td>
						</tr>
					</tbody>
				</table>
			</Grid>
			<Grid item xs={12} lg={6}>
				<img src={"https://stockchart.iceloof.com/"+item.date+"/"+item.ticker+".png"} alt={item.ticker} />
			</Grid>
		</Grid>
	)

  return (
    <>
			{data.map((item: Stock, index: number)=>Item(item, index))}
		</>
  );
};

export default MarketCompoent;
