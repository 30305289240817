import React, { useState } from 'react';
import { Grid, Box, Paper, InputBase, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Alert, AlertColor, AlertPropsColorOverrides } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import { delay } from '../services';
import icon from '../assets/images/marketwatcher.png';
import { postSubscribe } from '../api'; 
import { OverridableStringUnion } from '@mui/types';

const MarketWatcherSectionContainer = styled(Grid)(() => ({
  maxWidth: '1280px',
  margin: 'auto'
}));

const Icon = styled('img')({
	width: '60%',
});

const MarketWatcherSection: React.FC = () => {

  const [email, setEmail] = useState('');
  const [msg, setMsg] = useState('');
  const [severity, setSeverity] = useState<OverridableStringUnion<AlertColor, AlertPropsColorOverrides>>('success');
  const [showMessage, setShowMessage] = useState(false);

  const handleSubscribe = async () => {
    // Handle subscription logic here (e.g., send email to server)
    try {
      await postSubscribe({'type': 'MarketWatcher', 'email': email});
      setEmail('');
      setMsg('Thank You for Subscribing');
      setSeverity('success');
      setShowMessage(true);
      // Hide the message after 5 seconds
      await delay(5000);
      setShowMessage(false);
    } catch(e: Response|any) {
      setMsg(e.data.msg);
      setSeverity('warning');
      setShowMessage(true);
      // Hide the message after 5 seconds
      await delay(5000);
      setShowMessage(false);
    }
  };

  return (
		<MarketWatcherSectionContainer container>
      <Grid item xs={12} md={6}>
				<Box
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
					style={{overflow: 'hidden'}}
        	>
          <Icon
              src={icon} 
							alt="Market Watcher"
            />
        </Box>
      </Grid>
			<Grid item xs={12} md={6}>
				<Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="calc(100% - 60px)"
					style={{padding: '30px 25px'}}
        	>
					<div style={{textAlign: 'center'}}>
						<div style={{fontSize: '2.6rem', fontWeight: '900'}}>Market Watcher</div>
						<div style={{fontSize: '1.6rem', lineHeight: '3rem', fontWeight: '300', paddingTop: '25px'}}>
							Your daily compass in the stock market's sea of opportunities, guiding you to the most promising shores of investment with expertly curated picks from global exchanges.
						</div>
            <div style={{paddingTop: '25px'}}>
              {showMessage && 
                <>
                  <div style={{margin: '0 auto', paddingBottom: '15px', width: '300px'}}>
                    <Alert severity={severity}>
                      {msg}
                    </Alert>
                  </div>
                </>}
              <Paper
                  component="form"
                  sx={{ m: '0 auto', p: '2px 4px', display: 'flex', alignItems: 'center', width: 300 }}
                >
                <InputBase 
                  placeholder="Subscribe our daily stock picks"
                  type="email"
                  inputProps={{ 'aria-label': 'Subscribe our daily stock picks' }}
                  sx={{ ml: 1, flex: 1 }}
                  value={email}
                  onChange={(e)=>setEmail(e.target.value)}
                />
                <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleSubscribe}>
                  <EmailIcon />
                </IconButton>
              </Paper>
            </div>
					</div>
				</Box>
      </Grid>
    </MarketWatcherSectionContainer>
  );
};

export default MarketWatcherSection;
