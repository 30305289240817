import * as React from 'react';
import { Grid, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';

const TopNewsContainer = styled(Grid)(({ theme }) => ({
  maxWidth: '1280px',
  margin: 'auto'
}));

const WordCloudImage = styled('img')({
	width: '100%',
	objectFit: 'cover',
	objectPosition: 'center',
	height: '130%', // Increase the height by 30px to allow for cutting
	marginTop: '-15%', // Shift the image up to cut off the top 15px
	marginBottom: '-15%', // Shift the image down to cut off the bottom 15px
});

const currentDate = moment();

const TopNews: React.FC = () => {
  return (
		<TopNewsContainer container>
      <Grid item xs={12} md={6}>
				<Box
          display="grid"
          alignItems="center"
          justifyContent="center"
          height="calc(100% - 200px)"
					style={{fontSize: '2.8rem', fontWeight: '900', textAlign: 'center', padding: '100px 25px'}}
        	>
					What is happening around the world
          <div style={{fontSize: '1.8rem', fontWeight: '600', fontStyle: 'italic'}}>{currentDate.format('DD MMMM, YYYY')}</div>
				</Box>
      </Grid>
      <Grid item xs={12} md={6}>
				<Box
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
					style={{overflow: 'hidden'}}
        	>
          <WordCloudImage
              src="https://img.iceloof.com/topnews/topnews.png" 
							alt="Top News"
            />
        </Box>
      </Grid>
    </TopNewsContainer>
  );
};

export default TopNews;
