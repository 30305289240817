import React, { useState, useEffect, ReactNode } from 'react';
import { Container, Grid, Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { TravelAdvisory } from '../types';
import { config } from '../config';
import TravelMap from '../components/TravelMap';
import Footer from '../components/Footer';
import './styles/travel.scss';

const Travel: React.FC = () => {
    const [data, setData] = useState<TravelAdvisory[]>([]);
    const [filtereddata, setFilteredData] = useState<TravelAdvisory[]>([]);
    const [country, setCountry] = useState<string>('US');

	useEffect(() => {
        document.title = 'Global Travel Advisory - Iceloof';

        const getData = async () => {
            try {
              const url = `${config.url.API_URL}traveladvisory`;
              const response = await fetch(url);
              const traveldata: TravelAdvisory[] = await response.json();
              setData(traveldata);
            } catch(e: Response|any) {
            }
          }
        getData();
    }, []);

    useEffect(() => {
        setFilteredData(data.filter(d=>d.from===country));
    }, [data,country]);

    const handleChange = (event: any, child: ReactNode) => {
        setCountry(event.target.value as string);
        const filtered = data.filter(d=>d.from===event.target.value);
        setFilteredData(filtered);
    };

    return (
        <>
            <Container fixed>
                <Box className="travel">
                    <Grid container spacing={2}>
                        <Grid item xs={2} md={5}></Grid>
                        <Grid item xs={8} md={2}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel id="country-select-label">Country of Citizenship</InputLabel>
                                <Select
                                    labelId="country-select-label"
                                    id="country-select"
                                    value={country}
                                    onChange={handleChange}
                                    label="Country of Citizenship"
                                >
                                    <MenuItem value="US">United States</MenuItem>
                                    <MenuItem value="CA">Canada</MenuItem>
                                    <MenuItem value="NZ">New Zealand</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2} md={2}></Grid>
                        <Grid item xs={12}>
                            <TravelMap data={filtereddata} />
                        </Grid>
                        <Grid item md={12}><div className="lengend level4"></div> Do Not Travel&nbsp;&nbsp;&nbsp;<div className="lengend level3"></div> Avoid Non-Essential Travel&nbsp;&nbsp;&nbsp;<div className="lengend level2"></div> Exercise Increased Caution&nbsp;&nbsp;&nbsp;<div className="lengend level1"></div> Normal Security Precautions&nbsp;&nbsp;&nbsp;<div className="lengend other"></div> Other&nbsp;&nbsp;&nbsp;<div className="lengend nodata"></div> No Data</Grid> 
                        <Grid item md={12}>
                            We bring you up-to-date travel alerts and advisories sourced from the United States, Canada, and New Zealand, providing a clear and intuitive way to stay informed, ensuring you have the latest information before you embark on your journey. For detailed information, refer to the official sources:
                        </Grid> 
                        <Grid item xs={12} className="source">
                            <a href="https://travel.state.gov/content/travel/en/traveladvisories/traveladvisories.html/" target="_blank" rel="noreferrer">Travel Advisory for United States Citizen</a>
                            <br/>
                            <a href="https://travel.gc.ca/travelling/advisories" target="_blank" rel="noreferrer">Travel Advisory for Canadian Citizen</a>
                            <br/>
                            <a href="https://www.safetravel.govt.nz/travel-advice-levels" target="_blank" rel="noreferrer">Travel Advisory for New Zealand Citizen</a>
                            <br/>
                            <a href="https://www.smartraveller.gov.au/destinations" target="_blank" rel="noreferrer">Travel Advisory for Australian Citizen</a>
                            <br/>
                            <a href="https://www.gov.uk/foreign-travel-advice" target="_blank" rel="noreferrer">Travel Advisory for United Kingdom Citizen</a>
                            <br/>
                            <br/>
                            <h1><i>Stay safe and travel smart with us!</i></h1>
                        </Grid>
                    </Grid>
                    <Footer />
                </Box>
            </Container>
        </>
	);
}

export default Travel;