import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { delay } from '../services';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
interface Props {
	data: any,
	options: any,
	type: string,
	periods: {label: string, value: string}[],
	period: string,
	handlePeriodChange: Function
}

const HistoricalChart: React.FC<Props> = (props) => {
	const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight });
	useEffect(() => {
    // Handler to call on window resize
    const handleResize = async () => {
			setSize({
        width: 0,
        height: 0
      });
			await delay(100);
      // Set window width/height to state
      setSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }
    
    // Add event listener
    window.addEventListener('resize', handleResize);
    
    // Call handler right away so state gets updated with initial window size
    handleResize();
    
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
		<>
			{size.width>0?
				(<>
					<Box sx={{ borderBottom: 1, borderColor: 'divider', minWidth: '480px' }}>
						<Tabs value={props.period} scrollButtons="auto"
		aria-label="lab API tabs example">
						{props.periods.map((period: {label: string, value: string}, index: number) => (<Tab key={index} className="tab" label={period.label} value={period.value} onClick={() => props.handlePeriodChange(period.value)} />))}
						</Tabs>
					</Box>
					<div className="padding-20" style={{minWidth: '480px'}}>
						{props.type === 'Line' ? (<Line options={props.options} data={props.data} />):<></>}
					</div>
				</>):(<></>)
			}
    </>
  );
};

export default HistoricalChart;
