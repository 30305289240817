import React from 'react';
import Typist from 'react-typist';
import 'react-typist/dist/Typist.css';
import { AnimatedSentenceType } from '../types';

interface AnimatedSentenceProps {
  sentences: AnimatedSentenceType[];
	classes?: string;
}

const AnimatedSentence: React.FC<AnimatedSentenceProps> = ({ sentences, classes }) => {
  return (
    <div className={classes}>
			{sentences.map((sentence: AnimatedSentenceType, index: number) => (
				<Typist key={index} avgTypingDelay={80} startDelay={sentence.delay} cursor={{ hideWhenDone: sentence.hideWhenDone, hideWhenDoneDelay: sentence.hideWhenDoneDelay }} className={sentence.classes} >
						{sentence.sentence}<Typist.Delay ms={2000} />
				</Typist>
			))}
    </div>
  );
};

export default AnimatedSentence;
