import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import EBook from '../components/EBook';
import Footer from '../components/Footer';
import { delay } from '../services';
import './styles/about.scss';

const AboutContainer = styled(Grid)(() => ({
  maxWidth: '1280px',
  margin: '0 auto'
}));

const handleClickOnLink = (url: string) => {
  window.open(url, '_blank');
};

const elements = [
  (<>
    <div className="title">About <span>Iceloof</span></div>
    <div className="subtitle">
      Where Innovation Meets Possibility🚀
    </div>
  </>),
  (<>
    <div className="heading">Our Journey</div>
    <div className="details">Since 2013, <span>Iceloof</span> has been at the forefront of technological exploration. Our journey began with a spark of curiosity and a commitment to pushing boundaries, with a vision: to empower traders, investors, and tech enthusiasts with cutting-edge tools and insights. Over the years, we've evolved, adapted, diversified and grown, fueled by our passion for technology.</div>
    <br/>
    <div className="details">Today, we stand at the forefront of innovation, leveraging AI, Machine Learning, and NLP to analyze financial information. But we're not confined to finance. Our expertise extends to custom web, mobile, and software development. Whether it's revolutionizing trading algorithms or crafting elegant software solutions, we're driven by a passion for technology.</div>
  </>),
  (<>
    <div className="heading">What We Offer</div>
    <div className="subheading">Financial Intelligence and Real-Time Insights</div>
    <div className="details">At <span>Iceloof</span>, we believe that knowledge is power. Our AI-driven platform provides real-time financial market information - curated, analyzed, and delivered straight to your screen. Our models orchestrate a symphony of strategies: trend-following, sentiment analysis, and more. They adapt swiftly to market shifts, exploiting inefficiencies that human traders might overlook. It's not just about beating the market, it's about rewriting the rules.</div>
    <div className="subheading">Quantitative Trading Made Simple</div>
    <div className="details">Welcome to a world where money never sleeps, and your portfolio dances to an algorithmic beat. Unlock the power of algorithms with our quantitative trading platform. Seamlessly integrate third-party trading brokers' APIs, test your strategies, and execute trades with confidence. Our mission? No more sleepless nights glued to stock tickers. No more emotional rollercoasters. Instead, entrust your capital to the tireless guardians of wealth—the ones who don't panic, hesitate, or second-guess.</div>
  </>),
  (<>
    <div className="subheading">Tailored IT Solutions</div>
    <div className="details">From custom web development to mobile apps and software solutions, we're your tech partners. Need a sleek website? A robust mobile app? Or perhaps a specialized software tool? Look no further - Iceloof delivers tailored solutions that align with your goals.</div>
    <div className="subheading">Beyond the Horizon</div>
    <div className="details">We're not content with the status quo. Our team explores new ideas, experiments with emerging technologies, and pioneers solutions that shape the future.</div>
  </>),
  (<>
    <div className="heading">Our Founder</div>
    <div className="details">As an enthusiastic software developer with a comprehensive background in web, software, and embedded development, he possess a profound passion for coding and a commitment to excellence in programming. His expertise is enriched by experiences in public and private sectors, and a diverse portfolio of freelance projects. His keen interest in finance and IoT also led him to develop a proprietary quantitative trading system and smart home, showcasing my ability to blend technical expertise with financial acumen. He is an avid learner, constantly exploring new technologies to stay at the forefront of innovation, and seeking opportunities that will challenge him to further his skills. Want to know more about him? 👉<a href="/" onClick={(e: any)=>{e.preventDefault();handleClickOnLink("https://hurin.iceloof.com")}}>Hurin's Portfolio</a></div>
  </>),
  (<>
    <div className="heading">Our Vision</div>
    <div className="details">We're not just about numbers and code. We're dreamers, thinkers, and innovators. Our vision extends beyond IT solutions, it's about impact, empowerment, and leaving a legacy. We're constantly exploring new ideas, pushing boundaries, expanding our services, bridge gaps, solve problems, and ignite possibilities.</div>
    <br/>
    <div className="heading">Join the Journey</div>
    <div className="details">Whether you're a curious learner, a seasoned professional, or a tech enthusiast, <span>Iceloof</span> invites you to explore, learn, and and build something extraordinary. Connect with us, follow our updates, and let's shape the future together!</div>
  </>)
];

interface Size {
  width?: number;
  height?: number;
}

const About: React.FC = () => {
	const [size, setSize] = useState<Size>({ width:600, height:720 });
  useEffect(() => {
    document.title = 'About - Iceloof';
  }, []);

  // Listen for the window resize event
  useEffect(() => {
    // Function to update the state on window resize
    const resizeHandler = async () => {
      const width = window.innerWidth;
      if(width < 960) {
        setSize({ });
			  await delay(100);
        setSize({ width:360, height:540 });
      } else if(width < 1280) {
        setSize({ });
			  await delay(100);
        setSize({ width:440, height:600 });
      } else if(width >= 1280) {
        setSize({ });
			  await delay(100);
        setSize({ width:600, height:720 });
      }
    };
    resizeHandler(); 
    window.addEventListener("resize", resizeHandler);

    // Cleanup: Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  return (
    <>
      <div className="AboutUs">
        <div className="bg">
          <div className="filteredbg">
            <AboutContainer>
              <div style={{paddingTop: "96px"}}>
                <div className="mybook">
                {size&&size?.width?
                  <EBook elements={elements} width={size?.width} height={size?.height}/>:<></>}
                </div>
              </div>
            </AboutContainer>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
