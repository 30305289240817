import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Grid, Modal, Box } from '@mui/material';
import { timestamptoString } from '../services';
import { config } from '../config';

interface NewsItem {
  id?: number;
  title: string;
  summary: string;
	source: string;
	tags: string;
	sentiment: number;
	published: number;
}

const News: React.FC = () => {
  const [news, setNews] = useState<NewsItem[]>([]);
  const [lasttimestamp, setLasttimestamp] = useState(0); // Pagination state
  const loader = useRef(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedNews, setSelectedNews] = useState<NewsItem>();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Style for the modal box
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    maxWidth: '680px',
    bgcolor: '#f1f1f1',
    color: '#1d1d1d',
    border: '2px solid #1d1d1d',
    borderRadius: '15px',
    boxShadow: 24,
    p: 4,
  };

  const openModal = (item: NewsItem) => {
    setSelectedNews(item);
    handleOpen();
  }

  const getUniqueObjects = (array: any[], keys: any[]) => {
    return array.filter((item, index, self) =>
      index === self.findIndex((t) => 
        keys.every((key) => t[key] === item[key])
      )
    );
  }

  // Infinite scrolling logic
  const handleObserver = useCallback((entries: any[]) => {
    const target = entries[0];
    const fetchNewsData = async (timestamp: number = 0) => {
      const url = timestamp === 0 ? `${config.url.API_URL}realtimenews/30` : `${config.url.API_URL}realtimenews/${timestamp}/30`;
      setLoading(()=>true);
      try {
        const response = await fetch(url);
        const data = await response.json();
        setNews((prevNews) => {
          let newdata = [...prevNews, ...data];
          newdata = getUniqueObjects(newdata,['id']).sort((a,b) => b.published - a.published);
          return newdata;
        });
      } catch (error) {
        console.error('Error fetching latest news:', error);
      } finally {
        setLoading(false);
      }
    };
    if (target.isIntersecting && !loading) {
      try {
        if(lasttimestamp === 0 && news.length > 0) {
          const last = news[news.length-1].published;
          setLasttimestamp(()=>last);
          fetchNewsData(last);
        } else if (lasttimestamp !== 0 && news.length > 0) {
          const last = news[news.length-1].published;
          if(last < lasttimestamp) {
            setLasttimestamp(()=>last);
            fetchNewsData(last);
          }
        } else {
          fetchNewsData();
        }
      } catch (error) {
        console.error('Error fetching older news:', error);
      }
    }
  }, [lasttimestamp, loading, news]);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0
    };
    const fetchNews = async (timestamp: number = 0) => {
      const url = timestamp === 0 ? `${config.url.API_URL}realtimenews/30` : `${config.url.API_URL}realtimenews/${timestamp}/30`;
      setLoading(()=>true);
      try {
        const response = await fetch(url);
        const data = await response.json();
        setNews((prevNews) => {
          let newdata = [...prevNews, ...data];
          newdata = getUniqueObjects(newdata,['id']).sort((a,b) => b.published - a.published);
          return newdata;
        });
      } catch (error) {
        console.error('Error fetching latest news:', error);
      } finally {
        setLoading(false);
      }
    };
    const observer = new IntersectionObserver(handleObserver, option);
    const interval = setInterval(fetchNews, 60000); // Fetch latest news every minute
    if (loader.current) observer.observe(loader.current);
    return () => {observer.disconnect();clearInterval(interval);} // Clean up the observer on component unmount
  }, [handleObserver]);
  
  return (
    <>
      <div className="newsheader">Latest Global News</div>
      <div className="content">
        {news.map((item) => (
          <div key={item.id} className="item" onClick={()=>openModal(item)}>
            <div className="title">{item.title}</div>
            <Grid container spacing={2}>
              <Grid item xs={8} className="tags">{item.tags!==''?item.tags.split(',').map((tag,index)=><div key={index} className="tag"><span>{tag}</span> </div>):''}</Grid>
              <Grid item xs={4} className="time"><div className={`sentiment ${item.sentiment===1?'bg-positive':item.sentiment===-1?'bg-negative':'bg-neutral'}`}>{item.sentiment===1?'Positive':item.sentiment===-1?'Negative':'Neutral'}</div><div>{timestamptoString(item.published,"YYYY-MM-DD HH:mm")}</div></Grid>
            </Grid>
            {/* Render other item details */}
          </div>
        ))}
        <div ref={loader} /> {/* Invisible element to trigger infinite scrolling */}
        <div className="hide">{lasttimestamp}</div>
        <Modal
          open={open}
          onClose={handleClose}
          className="news-modal"
          aria-labelledby="news-modal-title"
          aria-describedby="news-modal-description"
        >
          <Box sx={style}>
            {selectedNews?(
              <>
                <div className="new-modal-title">
                  {selectedNews.title}
                </div>
                <div className="news-modal-source">
                  Source: {selectedNews.source}
                </div>
                <div className="news-modal-time">
                  Published: {timestamptoString(selectedNews.published,"YYYY-MM-DD HH:mm")}
                </div>
                <div className={`news-modal-sentiment ${selectedNews.sentiment===1?'bg-positive':selectedNews.sentiment===-1?'bg-negative':'bg-neutral'}`}>{selectedNews.sentiment===1?'Positive':selectedNews.sentiment===-1?'Negative':'Neutral'}</div>
                <div className="news-modal-summary">
                  {selectedNews.summary}
                </div>
                <div className="news-modal-tags">
                {selectedNews.tags!==''?selectedNews.tags.split(',').map(tag=><div className="tag"><span>{tag}</span> </div>):''}
                </div>
              </>
            ):(<></>)}
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default News;
