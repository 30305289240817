import React, { useEffect } from 'react';
import Footer from '../components/Footer';
import Maps from '../components/Maps';
import AnimatedSentence from '../components/AnimatedSentence';
import TopNews from '../components/TopNews';
import MarketWatcherSection from '../components/MarketWatcherSection';
import QuantTraderSection from '../components/QuantTraderSection';
import ServicesSection from '../components/ServicesSection';
import './styles/home.scss';

const Home: React.FC = () => {

	useEffect(() => {
    document.title = 'Iceloof - Innovating Your Digital Experience with AI-Driven Insights and Global Connectivity';
  }, []);

	const sentences = [{sentence:'Iceloof', classes: 'typist-header', delay: 2000, hideWhenDone: true}, {sentence: 'Innovating Your Digital Experience with AI-Driven Insights and Global Connectivity', classes: 'typist-subtitle', delay: 5000}];
	return (
		<>
			<div className="HomeMapSection">
				<Maps />
				<AnimatedSentence sentences={sentences} classes="typist-sentences"/>
			</div>
			<div className="TopNewsSection">
				<TopNews />
			</div>
			<div className="MarketWatcherSection">
				<MarketWatcherSection />
			</div>
			<div className="QuantTraderSection">
				<QuantTraderSection />
			</div>
			<div className="ServicesSection">
				<ServicesSection />
			</div>
			<Footer />
		</>
	);
}

export default Home;