import axios from 'axios';
import { config } from '../config';
import { Subscribe, Contact } from '../types';

export const postContact = (contact: Contact): any => {
  const data = {'content': '<div>Name: '+contact.name+'</div><div>Email: '+contact.email+'</div><div>Message: '+contact.message+'</div>'};
  return new Promise((resolve, reject) => {
    axios
      .post(config.url.API_URL+'contact', data, {headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response ? err.response : err);
        });
  });
}

export const postSubscribe = (subscribe: Subscribe): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(config.url.API_URL+'subscribe', subscribe, {headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response ? err.response : err);
        });
  });
}


export const Api = {
  postContact: postContact
}